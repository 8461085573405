.error-page {
  background: $navy;

  &__main {
    height: calc(100vh - 84px);
  }

  &__wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__section {
    width: 50%;
    &_img {
      text-align: center;
    }
  }

  .title-h1 {
    max-width: 360px;
    padding-bottom: 25px;
  }

  .text-small {
    padding-bottom: 35px;
  }

  .btn_orange:hover{
    color: $white;
  }
}

@media screen and (max-width: $tablet) {
  .error-page {
    &__main {
      height: calc(100vh - 80px);
    }

    &__wrap {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__section {
      width: 100%;
      text-align: center;
      &_img {
        padding-bottom: 95px;
        img {
          max-width: 100%;
        }
      }
    }

    .title-h1 {
      max-width: none;
    }

    .text-striped__text::before {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile) {
  .error-page {
    &__main {
      background-image: none;
      height: calc(100vh - 50px);
    }

    &__section {
      &_img {
        padding-bottom: 40px;
      }
    }

    .text-orange {
      display: block;
    }
    
    .text-small {
      padding-bottom: 60px;
      max-width: 235px;
      margin: auto;
    }
  }
}